import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../../components/section/policies/PoliciesNavBar';
import PoliciesContentArea from '../../../components/section/policies/PoliciesContentArea';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

const Page = () => (
  <Body header={header}>
    <SEO
      title="Acceptable Use Policy - Rotaready"
      url="policies/acceptable-use-policy"
    />

    <ResponsiveContainer>
      <ContentWrapper>
        <TitleWrapper>
          <H1 uistyle="brand160" text="Acceptable Use Policy" />
        </TitleWrapper>

        <BodyWrapper>
          <PoliciesNavBar />

          <PoliciesContentArea>
            <p><b>Last updated: 21 May 2018</b></p>

            <p>This Acceptable Use Policy sets out a list of acceptable and unacceptable conduct for our Services.
              If we believe a violation of the policy is deliberate, repeated or presents a credible risk of harm
              to other users, our customers, the Services or any third parties, we may suspend or terminate your
              access. This policy may change as Rotaready grows and evolves, so please check back regularly for
              updates and changes. Capitalised terms used below but not defined in this policy have the meaning
              set forth in the <Link to="/policies/user-terms-of-service">User Terms of Service</Link>.</p>

            <H2 uistyle="brand160">Do:</H2>

            <ul>
              <li>comply with all User Terms of Service, including the terms of this Acceptable Use Policy;</li>
              <li>comply with all applicable laws and governmental regulations, including, but not limited to, all
                intellectual property, data, privacy, and export control laws, and regulations promulgated by any
                government agencies;
              </li>
              <li>upload and disseminate only Customer Data to which Customer owns all required rights under law and
                under contractual and fiduciary relationships (such as proprietary and confidential information
                learned or disclosed as part of employment relationships or under nondisclosure agreements) and do
                so only consistent with applicable law;
              </li>
              <li>use commercially reasonable efforts to prevent unauthorised access to or use of the Services;</li>
              <li>keep passwords and all other login information confidential;</li>
              <li>monitor and control all activity conducted through your account in connection with the Services;
              </li>
              <li>promptly notify us if you become aware of or reasonably suspect any illegal or unauthorised activity
                or a security breach involving your accounts or teams, including any loss, theft, or unauthorised
                disclosure or use of a username, password, or account; and
              </li>
              <li>comply in all respects with all applicable terms of the third party applications, including any that
                Customer elects to integrate with the Services that you access or subscribe to in connection with
                the Services.
              </li>
            </ul>

            <H2 uistyle="brand160">Do not:</H2>

            <ul>
              <li>permit any third party that is not an Authorised User to access or use a username or password for
                the Services;
              </li>
              <li>share, transfer or otherwise provide access to an account designated for you to another person;</li>
              <li>use the Services to store or transmit any Customer Data that may infringe upon or misappropriate
                someone else's trademark, copyright, or other intellectual property, or that may be tortious or
                unlawful;
              </li>
              <li>upload to, or transmit from, the Services any data, file, software, or link that contains or
                redirects to a virus, Trojan horse, worm, or other harmful component or a technology that unlawfully
                accesses or downloads content or information stored within the Services or on the hardware of
                Rotaready or any third party;
              </li>
              <li>attempt to reverse engineer, decompile, hack, disable, interfere with, disassemble, modify, copy,
                translate, or disrupt the features, functionality, integrity, or performance of the Services
                (including any mechanism used to restrict or control the functionality of the Services), any third
                party use of the Services, or any third party data contained therein (except to the extent such
                restrictions are prohibited by applicable law);
              </li>
              <li>attempt to gain unauthorised access to the Services or related systems or networks or to defeat,
                avoid, bypass, remove, deactivate, or otherwise circumvent any software protection or monitoring
                mechanisms of the Services;
              </li>
              <li>access the Services in order to build a similar or competitive product or service or copy any ideas,
                features, functions, or graphics of the Services;
              </li>
              <li>use the Services in any manner that may harm minors or that interacts with or targets people under
                the age of thirteen;
              </li>
              <li>impersonate any person or entity, including, but not limited to, an employee of ours, an
                "Administrator", an "Owner", or any other Authorised User, or falsely state or otherwise
                misrepresent your affiliation with a person, organisation or entity;
              </li>
              <li>access, search, or create accounts for the Services by any means other than our publicly supported
                interfaces (for example, "scraping" or creating accounts in bulk);
              </li>
              <li>send unsolicited communications, promotions or advertisements, or spam;</li>
              <li>place any advertisements within a Rotaready client;</li>
              <li>send altered, deceptive or false source-identifying information, including "spoofing" or
                "phishing";
              </li>
              <li>sublicense, resell, timeshare or similarly exploit the Services;</li>
              <li>use the Services for consumer purposes, as Rotaready is intended for use by businesses and
                organisations;
              </li>
              <li>use contact or other user information obtained from the Services (including email addresses) to
                contact Authorised Users outside of the Services without their express permission or authority or to
                create or distribute mailing lists or other collections of contact or user profile information for
                Authorised Users for use outside of the Services; or
              </li>
              <li>authorise, permit, enable, induce or encourage any third party to do any of the above.</li>
            </ul>
          </PoliciesContentArea>
        </BodyWrapper>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default Page;
